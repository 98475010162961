@media only screen and (max-width: 600px) {
    .css-13wz8do{
        grid-template-columns: 2fr 0fr !important;
        gap: 0 !important;
    }

    .songs{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .welcomeToFR{
        text-align: center;
    }
}

.closeButton{
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    font-weight: 900;
}