@media only screen and (max-width: 1000px) {
.footerBox{
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
   }

  .quickLinks{
    padding-bottom: 10px;
    font-size: 20px;
  }

  .linkLine{
    padding-bottom: 10px;
    font-size: 20px !important;
  }

  .alias{
    text-align: center;
  }
}

@media only screen and (min-width: 1001px) {
   .footerBox{
    grid-template-columns: 3fr 1fr;
   }

   .quickLinks{
    padding-left: 40px;
   }

   .alias{
    padding-right: 40px;
    text-align: right;
   }
}