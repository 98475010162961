.aboutContent{
    padding-top: 2svh;
}


@media only screen and (max-width: 1000px) {
    .aboutContent{
        display: flex;
        flex-direction: column;
       }

       .drewPic{
        padding: 50px 0;
       }
  }

  @media only screen and (min-width: 1001px) {
    .aboutContent{
       grid-template-columns: 3fr 1fr;
       display: grid;
       gap: 30px;
       align-content: center;
      }
  }