.css-151xpih-MuiListItem-root{
    width: auto !important;
    padding: 0 !important;
    cursor: pointer;
}

.css-1mq6tvb-MuiListItem-root{
    width: auto !important;
    padding: 0 !important;
    cursor: pointer;
}



.css-46bh2p-MuiCardContent-root{
    background-color: #c6c6c6;
}

@media only screen and (max-width: 600px) {

    .songCard{
        width: 80dvw !important;
    }

    .commentInterface{
        display: block !important;
    }

    .desktop{
        display: none;
    }
}

@media only screen and (min-width: 1001px) {
    .userLinkSC:hover{
        color: blue !important;
        cursor: pointer;
    }
}